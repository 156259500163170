<template>
  <RecordViewWrapper>
    <sdPageHeader title="Sharing Knowledge">
      <template #subTitle>
        <sdButton
          class="btn-add_new"
          size="default"
          type="primary"
          v-if="checkPermission('create laporan manajemen sharing knowledge')"
        >
          <router-link to="/laporan-manajemen/sharing-knowledge/add">
            <sdFeatherIcons type="plus" size="14" /> Tambah</router-link
          >
        </sdButton>
      </template>
      <template #buttons>
        <div class="search-box">
          <span class="search-icon">
            <sdFeatherIcons type="search" size="14" />
          </span>
          <a-input
            @change="handleSearch"
            v-model:value.trim="formState.searchItem"
            type="text"
            name="recored-search"
            placeholder="Search Here"
          /></div
      ></template>
    </sdPageHeader>

    <Main>
      <a-row :gutter="15">
        <a-col class="w-100" :md="24">
          <sdCards headless>
            <div v-if="isLoading" class="spin">
              <a-spin />
            </div>

            <div v-else>
              <TableWrapper class="table-data-view table-responsive">
                <a-table
                  :pagination="{ pageSize: 10, showSizeChanger: true }"
                  :dataSource="dataSource"
                  :columns="columns"
                  bordered
                />
              </TableWrapper>
            </div>
          </sdCards>
        </a-col>
      </a-row>

      <a-modal v-model:visible="modalState.visible" title="Konfirmasi">
        <a-form
          layout="horizontal"
          :model="formModalState"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          labelAlign="left"
          :rules="modalRules"
          id="unapproveForm"
          @finish="doUnapprove"
        >
          Apakah Anda yakin ingin melakukan unapprove sharing knowledge {{ modalState.month }} {{ modalState.year }}?
          <a-form-item name="alasan" label="Alasan">
            <a-input v-model:value="formModalState.alasan" placeholder="Masukkan Alasan" />
          </a-form-item>
        </a-form>
        <template #footer>
          <a-button key="back" @click="handleCancel">Batal</a-button>
          <a-button class="sDash_form-action__btn" type="primary" size="large" htmlType="submit" form="unapproveForm">
            {{ modalState.isLoading ? 'Harap Menunggu...' : 'Unapprove' }}
          </a-button>
        </template>
      </a-modal>
    </Main>
  </RecordViewWrapper>
</template>

<script>
import { RecordViewWrapper } from '../../../components/crud/style';
import { computed, onMounted, reactive } from 'vue';
import { Main, TableWrapper } from '../../styled';
import { useStore } from 'vuex';
import { Modal } from 'ant-design-vue';
import { DataService } from '@/config/dataService/dataService';
import { notification } from 'ant-design-vue';
import { getItem } from '@/utility/localStorageControl';

const ViewPage = {
  name: 'ViewPage',
  components: { RecordViewWrapper, Main, TableWrapper },
  setup() {
    const { state, dispatch } = useStore();
    const crud = computed(() => state.crud.data);
    const isLoading = computed(() => state.crud.loading);
    const months = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const customRender = row => {
      if (row.record.sequence == 1) {
        return {
          children: row.text,
          props: {
            rowSpan: row.record.realisasi == 0 ? 1 : row.record.realisasi,
          },
        };
      }
      return {
        children: row.text,
        props: {
          rowSpan: 0,
        },
      };
    };
    const profile = getItem('profile_plnbb');
    const permissions = getItem('permissions');

    const columns = [
      {
        title: 'Bulan',
        dataIndex: 'month_year',
        customRender: customRender,
      },
      {
        title: 'Target per bulan',
        dataIndex: 'target',
        customRender: customRender,
      },
      {
        title: 'Jumlah',
        dataIndex: 'realisasi',
        customRender: customRender,
      },
      {
        title: 'Tanggal Pelaksanaan',
        dataIndex: 'tanggal_pelaksanaan',
      },
      {
        title: 'Judul',
        dataIndex: 'judul',
      },
      {
        title: 'Nama Pemateri',
        dataIndex: 'pemateri',
      },
      {
        title: 'Materi',
        dataIndex: 'url_materi',
      },
      {
        title: 'Foto Pelaksanaan',
        dataIndex: 'url_foto',
      },
      {
        title: 'Sumber Materi',
        dataIndex: 'url_sumber_materi',
      },
      {
        title: 'Status',
        dataIndex: 'status_approval',
        customRender: customRender,
        key: 'status_approval',
      },
      {
        title: 'Actions',
        dataIndex: 'action',
        customRender: customRender,
        key: 'action',
        width: '90px',
      },
    ];

    const modalState = reactive({
      isLoading: false,
      visible: false,
      month: '',
      year: null,
    });

    const formModalState = reactive({
      id: null,
      alasan: '',
    });

    const labelCol = {
      lg: 6,
      md: 9,
      xs: 24,
    };

    const wrapperCol = {
      lg: 18,
      md: 15,
      xs: 24,
    };

    onMounted(() => {
      getData();
    });

    const getData = () => {
      dispatch('axiosCrudGetData', 'laporan-sharing-knowledge');
    };

    const handleDelete = (id, name) => {
      Modal.confirm({
        title: 'Konfirmasi',
        content: `Apakah Anda yakin ingin menghapus ${name}?`,
        okText: 'Hapus',
        cancelText: 'Batal',
        onOk: () => {
          dispatch('axiosDataDelete', {
            id,
            url: 'laporan-sharing-knowledge',
            getData: () => {
              dispatch('axiosCrudGetData', 'laporan-sharing-knowledge');
            },
          });
        },
      });
    };

    const formState = reactive({
      searchItem: '',
    });

    const dataSource = computed(() => {
      if (crud.value && crud.value.data && crud.value.data.lists) {
        let lists = crud.value.data.lists.map((item, key) => {
          let color;

          switch (item.status) {
            case 'CRTD':
              color = 'blue';
              break;
            case 'UPDT':
              color = 'orange';
              break;
            case 'REL':
              color = 'green';
              break;
            case 'UNREL':
              color = 'volcano';
              break;
            case 'DEL':
              color = 'red';
              break;
          }

          let update = checkPermission('update laporan manajemen sharing knowledge') ? (
            <router-link class="edit" to={`/laporan-manajemen/sharing-knowledge/${item.header_id}`}>
              <sdFeatherIcons type="edit" size={14} title="Ubah" />
            </router-link>
          ) : null;

          let approve = checkPermission('approve laporan manajemen sharing knowledge') ? (
            <a class="edit" onClick={() => handleApprove(item.header_id, item.month, item.year)}>
              <sdFeatherIcons type="check-square" size={14} title="Approve" />
            </a>
          ) : null;

          let del = checkPermission('delete laporan manajemen sharing knowledge') ? (
            <router-link
              class="delete"
              onClick={() => handleDelete(item.header_id, item.month + '-' + item.year)}
              to=""
            >
              <sdFeatherIcons type="trash-2" size={14} title="Hapus" />
            </router-link>
          ) : null;

          let unapprove = checkPermission('approve laporan manajemen sharing knowledge') ? (
            <a class="edit" onClick={() => handleUnapprove(item.header_id, item.month, item.year)}>
              <sdFeatherIcons type="rotate-ccw" size={14} title="Unapprove" />
            </a>
          ) : null;

          item.key = key + 1;
          item.month_year = item.month + '-' + item.year;
          item.status_approval = <a-tag color={color}>{item.status}</a-tag>;
          item.action = (
            <div class="table-actions">
              {item.status != 'REL' ? (
                <>
                  {update}
                  {approve}
                  {del}
                </>
              ) : (
                { unapprove }
              )}
            </div>
          );
          return item;
        });
        return lists;
      }
      return [];
    });

    const handleSearch = () => {
      dispatch('axiosDataSearch', { url: 'laporan-sharing-knowledge', filter: formState.searchItem });
    };

    const handleApprove = (id, month, year) => {
      Modal.confirm({
        title: 'Konfirmasi',
        content: `Apakah Anda yakin ingin melakukan approve sharing knowledge ${months[month]} ${year}?`,
        okText: 'Approve',
        cancelText: 'Batal',
        onOk: () => {
          dispatch('axiosDataApprove', {
            id,
            url: 'approve-laporan-sharing-knowledge',
            getData: () => {
              getData();
            },
          });
        },
      });
    };

    const handleUnapprove = (id, month, year) => {
      formModalState.id = id;
      modalState.month = months[month];
      modalState.year = year;
      modalState.visible = true;
    };

    const modalRules = {
      alasan: [
        {
          required: true,
          message: 'Harap Diisi',
          trigger: 'blur',
        },
      ],
    };

    const handleCancel = () => {
      modalState.visible = false;
    };

    const doUnapprove = () => {
      modalState.isLoading = true;

      DataService.post('unapprove-laporan-sharing-knowledge/' + formModalState.id, {
        alasan: formModalState.alasan,
      }).then(response => {
        modalState.visible = false;
        modalState.isLoading = false;
        formModalState.id = null;
        formModalState.alasan = '';
        notification.success({
          message: response.data.message,
        });

        getData();
      });
    };

    const checkPermission = permission => {
      if (profile.roles[0].name == 'Super Admin') {
        return true;
      }

      return permissions.includes(permission);
    };

    return {
      formState,
      columns,
      isLoading,
      crud,
      dataSource,
      handleDelete,
      handleSearch,
      handleApprove,
      handleUnapprove,
      labelCol,
      wrapperCol,
      modalState,
      handleCancel,
      doUnapprove,
      formModalState,
      modalRules,
      checkPermission,
    };
  },
};

export default ViewPage;
</script>
